import Form from 'react-bootstrap/Form';
import { Button, Col, Container, Row, Stack, } from 'react-bootstrap';
import MenuCelular from './MenuCelular';
import MenuPrincipal from './MenuPrincipal';
import { ChangeEvent, useEffect, useState } from 'react';
import { useKeycloak } from 'keycloak-react-web';
import { useNavigate, useParams } from 'react-router-dom';
import { useSendGet, useSendPost, useSendPut } from '../Server';

function SecretariaEditor() {
	const { id } = useParams();
	const keycloak = useKeycloak();

	const navigate = useNavigate();

	const [dados, setDados] = useState<any>({});
	const [acessos, setAcessos] = useState<any>([]);

	const [selected, setSelected] = useState("dados");

	const sendPostF = useSendPost();
	const sendPut = useSendPut();

	const sendGet = useSendGet();



	function sendDados() {
		let update = id ? sendPut : sendPostF;

		let d = { ...dados };
		const path = "secretaria";
		update(
			id ? path + "/" + id : path, d,
			(response: any) => {
				console.log('data', response.data);
				navigate("/home")
			});
	}

	useEffect(() => {
		setTimeout(() => {
			sendGet("secretaria/acessos", (response: any) => {
				setAcessos(response.data);
			});
		}, 0)
	}, []);


	useEffect(() => {
		if (id) {
			sendGet("secretaria/" + id, (response: any) => {
				setDados(response.data);
			});
		}
	}, [id]);

	function atualizaAcessos(e: ChangeEvent<HTMLInputElement>) {
		const filtered = (dados.acessos || []).filter((a: any) => a !== e.target.id);
		const res = e.target.checked ? filtered.concat(e.target.id) : filtered;
		console.log(res);
		return res;

	}

	return <>
		<MenuPrincipal />
		<Container>
			<Row className='mt-5'>
				<Col>
					<h1>Secretária</h1>
				</Col>
			</Row>
			<Form className="mt-3">
				<Row>
					<Form.Group className="mb-3" >
						<Form.Label>E-mail</Form.Label>
						<Form.Control id="email" type="input" placeholder="Digite o seu e-mail"
							value={dados.email} onChange={e => setDados({ ...dados, email: e.target.value })} />
					</Form.Group>
				</Row>
				<Row>
					<Form.Group className="mb-3" >
						<Form.Label>Telefone</Form.Label>
						<Form.Control id="telefone" type="input" placeholder="Digite apenas os números do telefone"
							value={dados.telefone} onChange={e => setDados({ ...dados, telefone: e.target.value })} />
					</Form.Group>
				</Row>
				<Row>
					<Form.Group className="mb-3" >
						<Form.Label>CPF</Form.Label>
						<Form.Control id="cpf" type="input" placeholder="Digite apenas os números do CPF"
							value={dados.cpf} onChange={e => setDados({ ...dados, cpf: e.target.value })} />
					</Form.Group>
				</Row>
				<Row>
					<Form.Group className="mb-3" >
						<Form.Label>Acessos</Form.Label>
						{acessos.map((a: any) => (
							<Col>
								<Form.Check type="checkbox" id={a.tipoAcesso} label={a.nome}
									onChange={e => setDados({ ...dados, acessos: atualizaAcessos(e) })} />
							</Col>
						))}
					</Form.Group>
				</Row>
				<Row className="justify-content-end">
					<Col sm={{ span: 4 }}>
						<Stack gap={2} direction="horizontal">
							<Form.Group className="mt-3" >
								<Button type='button' variant='secondary'>Cancelar</Button>
							</Form.Group>
							<Form.Group className="mt-3" >{
								id ? <Button type='button' variant='primary' onClick={() => sendDados()}>Atualizar</Button> :
									<Button type='button' variant='primary' onClick={() => sendDados()}>Incluir</Button>
							}
							</Form.Group>
						</Stack>
					</Col>
				</Row>


			</Form>
		</Container>
		<MenuCelular />
	</>
}
export default SecretariaEditor;
