import React, { StrictMode } from 'react';

import { createRoot } from 'react-dom/client';

import './index.css';
import AppRouter from './AppRouter';
import reportWebVitals from './reportWebVitals';

import { KeycloakProvider } from "keycloak-react-web";
import Keycloak from 'keycloak-js';


import 'bootstrap/dist/css/bootstrap.min.css';

const keycloak = new Keycloak({
 url: process.env.REACT_APP_KEYCLOAK_URL,
 realm: "Prescrito",
 clientId: "React-auth",
});

const root = createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <KeycloakProvider key="keycloakprovider" client={keycloak as any}>
    <StrictMode>
      <AppRouter />
    </StrictMode>
  </KeycloakProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
