import Form from 'react-bootstrap/Form';
import { Button, ButtonGroup, Card, Col, Container, Dropdown, ListGroup, Nav, Row, Stack, Table } from 'react-bootstrap';
import MenuCelular from './MenuCelular';
import MenuPrincipal from './MenuPrincipal';
import { useEffect, useState } from 'react';
import Pesquisa from '../componentes/Pesquisa';
import { useKeycloak } from 'keycloak-react-web';
import { redirect, useParams } from 'react-router-dom';
import { useSendGet, useSendPost, useSendPut } from '../Server';

function PacienteEditor() {
  const {id} = useParams();
  const keycloak = useKeycloak();
  const [dados, setDados] = useState<any>({ alergias: [], condicoes: [], condicoesPadrao: [] });

  const [selected, setSelected] = useState("dados");
  

  const dadosHistorico:any = [
    //{ data: '15/03/2021', observacao: "Dores nas costas, dipirona", documentos: [{ tipo: "Prescrição", codigo: 123 }] }
  ];

  const [listaEstados, setListaEstados] = useState([
    { id: 1, nome: "Acre" },
    { id: 2, nome: "Alagoas" },
    { id: 3, nome: "Amapá" },
    { id: 4, nome: "Amazonas" },
    { id: 5, nome: "Bahia" },
    { id: 6, nome: "Ceará" },
    { id: 7, nome: "Distrito Federal" },
    { id: 8, nome: "Espírito Santo" },
    { id: 9, nome: "Goiás" },
    { id: 10, nome: "Maranhão" },
    { id: 11, nome: "Mato Grosso" },
    { id: 12, nome: "Mato Grosso do Sul" },
    { id: 13, nome: "Minas Gerais" },
    { id: 14, nome: "Pará" },
    { id: 15, nome: "Paraíba" },
    { id: 16, nome: "Paraná" },
    { id: 17, nome: "Pernambuco" },
    { id: 18, nome: "Piauí" },
    { id: 19, nome: "Rio de Janeiro" },
    { id: 20, nome: "Rio Grande do Norte" },
    { id: 21, nome: "Rio Grande do Sul" },
    { id: 22, nome: "Rondônia" },
    { id: 23, nome: "Roraima" },
    { id: 24, nome: "Santa Catarina" },
    { id: 25, nome: "São Paulo" },
    { id: 26, nome: "Sergipe" },
    { id: 27, nome: "Tocantins" }
  ]);

  const [listaSexo, setListaSexo] = useState([
    { id: '1', nome: 'Feminino' },
    { id: '2', nome: 'Masculino' },
  ]);

  const [listaRaca, setListaRaca] = useState([
    { id: '1', nome: 'Branco' },
    { id: '2', nome: 'Preto' },
    { id: '3', nome: 'Amarelo' },
    { id: '4', nome: 'Pardo' },
    { id: '5', nome: 'Indígena' },
    { id: '6', nome: 'Não declaradoo' },
  ]);

  const [listaCidades, setListaCidades] = useState([
    { id: '1', nome: 'GOIÂNIA' },
    { id: '2', nome: 'APARECIDA DE GOIANIA' },
    { id: '3', nome: 'ANAPOLIS' },
    { id: '4', nome: 'TRINDADE' },
    { id: '5', nome: 'SENADOR CANEDO' },
    { id: '6', nome: 'HIDROLANDIA' }
  ]);

  const [alergias, setAlergias] = useState([
    { id: 1, nome: 'Glutem' },
    { id: 2, nome: 'Crostácios' },
    { id: 3, nome: 'Lacteos' },
    { id: 4, nome: 'Amendoins' },
    { id: 5, nome: 'Pescado' },
    { id: 6, nome: 'Frutos Secos' },
    { id: 7, nome: 'Ovos' },
    { id: 8, nome: 'Moluscúlos' },
    { id: 9, nome: 'Mostarda' },
    { id: 10, nome: 'Sementes de Sésamo' },
    { id: 11, nome: 'Dioxido Enxofre e Sulfitos' },
  ]);

  const [condicoes, setCondicoes] = useState([
    { id: 1, nome: 'Diabetes' },
  ]);

  const sendGet = useSendGet();

  useEffect(() => {
	if(!id) return;
	sendGet("paciente/"+id,  (response:any) => {
		let data = response.data;
		data.cidadeObj = {id: 1,  nome: data.cidade};
		setDados(data);
	});
  },[id]);

  const sendPostF = useSendPost();
  const sendPut = useSendPut();

  function sendPost() {
	let update = id ? sendPut: sendPostF;

	let d = {...dados, cidade: dados.cidadeObj.nome};
	update(
		id ? "paciente/" + id: "paciente", 
		d,  
		(response:any) => {
        console.log('data', response.data);
        let pacientes = null;
        if((pacientes = localStorage.getItem("pacientes")) != null){
		  let data = JSON.parse(pacientes).data
            	.filter((p:any) => p.paciente !== response.data.paciente)
          localStorage.setItem("pacientes", JSON.stringify(
			{lastUpdate: Date.now(), data: [...data, response.data]}));
        }
        redirect("/pacientes");
	});
  }

  useEffect(() => {
    console.log(dados);
  },[dados])

  function updateCondicaoItemCheckbox(ev:any): void {
    console.log(ev.target) 
    if(ev.target.checked){
      setDados({...dados, condicoesPadrao: 
        [...dados.condicoesPadrao, {id: ev.target.id}]});
    }
    else {
      setDados({...dados, condicoesPadrao:
        dados.condicoesPadrao.filter((i:any) => i.id !== ev.target.id)});
    }
  }

  return <>
    <MenuPrincipal />
    <Container className="mt-3">
      <Row>
        <Col>
          <h1>Paciente</h1>
        </Col>
      </Row>
      <Form>
        <Row>
          <Col md={{ span: 4 }}>
            <Card className="d-none d-md-block">
              <Card.Img variant="top" />
              <Card.Body>
                <Card.Text>
                  Acesse abaixo as informações do paciente.
                  <p className='fw-bold'>{dados.nome}</p>
                </Card.Text>
              </Card.Body>
              <ListGroup className="list-group-flush">
                <ListGroup.Item onClick={() => setSelected("dados")} active={selected === 'dados'}>Dados gerais</ListGroup.Item>
                <ListGroup.Item onClick={() => setSelected("contato")} active={selected === 'contato'} >Contato e Endereço</ListGroup.Item>
                <ListGroup.Item onClick={() => setSelected("outros_dados")} active={selected === 'outros_dados'} >Outros dados</ListGroup.Item>
                <ListGroup.Item onClick={() => setSelected("informacoes")} active={selected === 'informacoes'} >Informações</ListGroup.Item>
                <ListGroup.Item onClick={() => setSelected("condicoes")} active={selected === 'condicoes'} >Condições</ListGroup.Item>
                <ListGroup.Item onClick={() => setSelected("alergias")} active={selected === 'alergias'}>Alergias</ListGroup.Item>
                <ListGroup.Item onClick={() => setSelected("historico")} active={selected === 'historico'} >Historico</ListGroup.Item>
              </ListGroup>
            </Card>
          </Col>
          <Col className={(selected === 'dados' ? 'd-block' : 'd-md-none') + " mb-5"} md={{ span: 8 }}>
            <h3>Dados</h3>
            <Form.Group className="mb-3" >
              <Form.Label>Nome</Form.Label>
              <Form.Control id="nome" name='nome' type="input" placeholder="Digite o nome do seu paciente"
                value={dados.nome} onChange={e => setDados({ ...dados, nome: e.target.value })} />
            </Form.Group>
            <Form.Group className="mb-3" >
              <Form.Label>CPF</Form.Label>
              <Form.Control id="cpf" type="input" placeholder="Digite apenas os números do CPF"
                value={dados.cpf} onChange={e => setDados({ ...dados, cpf: e.target.value })} />
            </Form.Group>
            <Form.Group className="mb-3" >
              <Form.Label>Data Nascimento</Form.Label>
              <Form.Control id="dataNascimento" type="date" placeholder="Selecione a data de nacimento"
                value={dados.dataNascimento} onChange={e => setDados({ ...dados, dataNascimento: e.target.value })} />
            </Form.Group>
          </Col>
          <Col className={(selected === 'contato' ? 'd-block' : 'd-md-none') + " mb-5"} md={{ span: 8 }}>
            <h3>Contato e Endereço</h3>
            <Form.Group className="mb-3" >
              <Form.Label>Telefone</Form.Label>
              <Form.Control id="telefone" type="input" placeholder="Digite o telefone do seu paciente"
                value={dados.telefone} onChange={e => setDados({ ...dados, telefone: e.target.value })} />
            </Form.Group>
            <Form.Group className="mb-3" >
              <Form.Label>E-mail</Form.Label>
              <Form.Control id="email" type="input" placeholder="Digite o email do seu paciente"
                value={dados.email} onChange={e => setDados({ ...dados, email: e.target.value })} />
            </Form.Group>
            <Form.Group className="mb-3" >
              <Form.Label>Endereço</Form.Label>
              <Form.Control id="endereco" type="input" placeholder="Digite o endereço do seu paciente"
                value={dados.endereco} onChange={e => setDados({ ...dados, endereco: e.target.value })} />
            </Form.Group>
            <Form.Group className="mb-3" >
              <Form.Label>Estado</Form.Label>
              <Form.Select id="estado" aria-label="Selecione a raça do seu paciente"
                onChange={e => setDados({ ...dados, estado: e.target.value })}>
                <option>Selecione</option>
                {listaEstados.map((e) => <option selected={e.id == dados.estado} value={e.id}>{e.nome}</option>)}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" >
              <Form.Label>Cidade</Form.Label>
              <Pesquisa id="cidade" selecao={dados.cidadeObj} setSelecao={(s: any) => setDados({ ...dados, cidadeObj: s})} itens={listaCidades} placeholder='Selecione a cidade' />
            </Form.Group>
          </Col>
          <Col className={(selected === 'outros_dados' ? 'd-block' : 'd-md-none') + " mb-5"} md={{ span: 8 }}>
            <h3>Outros dados</h3>
            <Form.Group className="mb-3" >
              <Form.Label>Nome mãe</Form.Label>
              <Form.Control type="input" placeholder="Digite o nome da mãe do seu paciente"
                id="nomeMae" value={dados.nomeMae} onChange={e => setDados({ ...dados, nomeMae: e.target.value })} />
            </Form.Group>
            <Form.Group className="mb-3" >
              <Form.Label>Profissão</Form.Label>
              <Form.Control type="input" placeholder="Digite a profissão do seu paciente"
                id="profissao" value={dados.profissao} onChange={e => setDados({ ...dados, profissao: e.target.value })} />
            </Form.Group>
          </Col>
          <Col className={(selected === 'informacoes' ? 'd-block' : 'd-md-none') + " mb-5"} md={{ span: 8 }}>
            <h3>Informações</h3>
            <Form.Group className="mb-3" >
              <Form.Label>Sexo</Form.Label>

              <Form.Select aria-label="Selecione a raça do seu paciente"
                id="sexo" onChange={e => setDados({ ...dados, sexo: e.target.value })} >
                <option>Selecione</option>
                {listaSexo.map((i) => <option selected={dados.sexo == i.id} value={i.id}>{i.nome}</option>)}
              </Form.Select>

            </Form.Group>
            <Form.Group className="mb-3" >
              <Form.Label>Peso</Form.Label>
              <Form.Control type="input" placeholder="Digite o peso do seu paciente"
                id="peso" value={dados.peso} onChange={e => setDados({ ...dados, peso: e.target.value })} />
            </Form.Group>
            <Form.Group className="mb-3" >
              <Form.Label>Altura</Form.Label>
              <Form.Control type="input" placeholder="Digite a altura do seu paciente"
                id="altura" value={dados.altura} onChange={e => setDados({...dados, altura: e.target.value})} />
            </Form.Group>
            <Form.Group className="mb-3" >
              <Form.Label>Raça</Form.Label>
              <Form.Select aria-label="Selecione a raça do seu paciente"
                id="raca" onChange={e => setDados({ ...dados, raca: e.target.value })} >
                <option>Selecione</option>
                {listaRaca.map((i) => <option selected={dados.raca == i.id} value={i.id}>{i.nome}</option>)}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col className={(selected === 'condicoes' ? 'd-block' : 'd-md-none') + " mb-5"} md={{ span: 8 }}>
            <h3>Condições</h3>
            <Row className="mt-3">
              <Stack direction="horizontal" gap={4}>
                <Form.Check type="checkbox" label="Gravidez" id="gravidez" 
                  onChange={(e) => updateCondicaoItemCheckbox(e)} />

                <Form.Check type="checkbox" label="Lactaçao" id="lactacao" 
                  onChange={(e) => updateCondicaoItemCheckbox(e)} />

                <Form.Check type="checkbox" label="Hipertensão" id="hipertensão" 
                  onChange={(e) => updateCondicaoItemCheckbox(e)} />
              </Stack>
            </Row>
            <Lista pesquisaItens={condicoes} placeholder='Selecione aqui outra condição' itens={dados.condicoes} setItens={(itens: any[]) => setDados({ ...dados, condicoes: itens })} />
          </Col>
          <Col className={(selected === 'alergias' ? 'd-block' : 'd-md-none') + " mb-5"} md={{ span: 8 }}>
            <Lista pesquisaItens={alergias} label='Alergias' placeholder='Selecione o alergenico' itens={dados.alergias} setItens={(itens: any[]) => setDados({ ...dados, alergias: itens })} />
          </Col>
          <Col className={(selected === 'historico' ? 'd-block' : 'd-md-none') + " mb-4"} md={{ span: 8 }}>
            <h3>Histórico</h3>
            <Row className="mt-3">
              <Col>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Data</th>
                      <th>Observação</th>
                      <th>Documentos</th>
                    </tr>
                  </thead>
                  {
                    dadosHistorico.map((item:any) => (
                      <tr className=''>
                        <td>{item.data}</td>
                        <td>{item.observacao}</td>
                        <td>{item.documentos.map((i:any) => <Nav.Link href={"prescricao/" + i.codigo}>{i.tipo}</Nav.Link>)}</td>
                      </tr>
                    ))
                  }
                </Table>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="justify-content-end">
          <Col sm={{ span: 4 }}>
            <Stack gap={2} direction="horizontal">
              <Form.Group className="mt-3" >
                <Button type='button' variant='secondary'>Cancelar</Button>
              </Form.Group>
              <Form.Group className="mt-3" >{ 
					id ? <Button type='button' variant='primary' onClick={() => sendPost()}>Atualizar</Button> :
						<Button type='button' variant='primary' onClick={() => sendPost()}>Incluir</Button> 
				}
              </Form.Group>
            </Stack>
          </Col>
        </Row>
      </Form>
    </Container>
    <MenuCelular />
  </>
}

function Lista({ itens, setItens, label, placeholder, pesquisaItens }:
  { itens: any[], setItens: Function, label?: string, placeholder: string, pesquisaItens: any[] }) {

  itens = itens? itens: [];
  const [selecao, setSelecao] = useState<any>({});

  function incluirItem() {
    setItens([...itens, selecao])
    setSelecao(null);
  }

  function removerItem(item: any) {
    setItens(itens.filter((i) => i != item))
  }
  

  return <>
    {label ?
      <h3>{label}</h3>
      : ""}
    <Row className='mt-3'>
      <Col xs={{ span: 8 }} md={{ span: 10 }}>
        <Pesquisa selecao={selecao} setSelecao={setSelecao} placeholder={placeholder} itens={pesquisaItens} />
      </Col>
      <Col xs={{ span: 4 }} md={{ span: 2 }}>
        <Button onClick={() => incluirItem()}>Incluir</Button>
      </Col>
    </Row>
    <Row className="mt-3">
      <Col>
        <ListGroup className="justify-content-between">
          {itens.map((i) => <ListGroup.Item as="li" className="d-flex justify-content-between " >
            <div className="mt-1">{i.nome}</div>
            <Button variant="link" className="secondary" onClick={() => removerItem(i)}>Remover</Button>
          </ListGroup.Item>)}
        </ListGroup>
      </Col>
    </Row>
  </>
}
export default PacienteEditor;


