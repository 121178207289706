import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import { Button, ButtonGroup, Card, Col, Dropdown, Row, Table } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import MenuPrincipal from './MenuPrincipal';
import MenuCelular from './MenuCelular';
import { useNavigate } from 'react-router-dom';
import Config from './Config';
import {useSendGet} from '../Server';


export const usePacientesDados = function(setPacientes: Function) {
	const sendGet = useSendGet();

  var get = false;

	return () => {
		let local: any = localStorage.getItem("pacientes");
		const shouldUpdate = Config.get().shouldUpdate;


		if (local == null || shouldUpdate("pacientes", JSON.parse(local).lastUpdate)) {
      if(get) return;
      get = true;

			sendGet("paciente/medico",
				(response: any) => {
					if(response.data[0].statusCode){
						setPacientes([]);
						return;
					}

					setPacientes(response.data);
					localStorage.setItem("pacientes", JSON.stringify(
						{ lastUpdate: Date.now(), data: response.data }));

          get = false;
				});
		}
		else {
			let pacientes:any = JSON.parse(local).data;
			setPacientes(pacientes ? pacientes : []);
		}
	}
}

function Pacientes() {
	const [pesquisa, setPesquisa] = useState("");
	const navigate = useNavigate();

	const [pacientes, setPacientes] = useState([]);

	function novoPaciente(): void {
		navigate("/paciente-editor")
	}

	const pacientesDados = usePacientesDados(setPacientes);

	useEffect(() => {
		pacientesDados();
	}, []);


	return (
		<>
			<MenuPrincipal />
			<Container>
				<Row className='mt-5'>
					<Col xs={{ span: 7 }} md={{ span: 8 }}>
                <p className="h1">Pacientes</p>
                <p className="d-none d-md-block">Você pode ver pesquisar e visualizar detalhes de pacientes aqui. Pode também criar receitas e outros documentos.</p>
					</Col>
					<Col xs={{ span: 5 }} md={{ span: 4 }}>
						<Button type="button" onClick={()=> novoPaciente()}>Novo paciente</Button>
					</Col>
				</Row>
				<Form.Group className="mt-5" controlId="formBasicEmail">
					<Row>
						<Col xs={{ span: 8 }} md={{ span: 10 }}>
							<Form.Control type="input" placeholder="Pesquisar por nome ou CPF" value={pesquisa} onChange={e => setPesquisa(e.target.value)} />
						</Col>
						<Col xs={{ span: 4 }} md={{ span: 2 }}>
							<Button type="button" >Pesquisar</Button>
						</Col>
					</Row>
				</Form.Group>
				<Row className='d-lg-none'>
					{
						pacientes.map((item:any) => (
							<Col xs={{ span: 12 }} md={{ span: 6 }} lg={{ span: 3 }}>
								<Card className='mt-3'>
									<Card.Body>
										<Card.Title>{item.nome}</Card.Title>
										<Card.Subtitle className="mb-2 text-muted">{item.condicoes}</Card.Subtitle>
										<Card.Text>Última consulta {item.ultima_consulta}</Card.Text>
										{dropDown(() => navigate("/paciente-editor/" + item.paciente))}
									</Card.Body>
								</Card>
							</Col>
						))
					}
				</Row>
				<Row className='d-none d-lg-block mt-3'>
					<Col lg={{span: 12}}>
						<Table striped bordered hover>
						  <thead>
							<tr>
							  <th>Nome</th>
							  <th>Condições</th>
							  <th>Data Ultima Consulta</th>
							  <th></th>
							</tr>
						  </thead>
						{
							pacientes.map((item:any) => (
								<tr className=''>
									<td>{item.nome}</td>
									<td>{item.condicoes}</td>
									<td>{item.ultima_consulta}</td>
									<td>
										{dropDown(() => navigate("/paciente-editor/" + item.paciente))}
									</td>
								</tr>
							))
						}
					    </Table>
					</Col>
				</Row>
			</Container>
			<MenuCelular />
		</>
	);

    function dropDown(verDetalhes:Function) {
        return <Dropdown as={ButtonGroup}>
            <Button variant="outline-primary" onClick={() => verDetalhes()}>Ver detalhes</Button>

            <Dropdown.Toggle split variant="outline-primary" id="dropdown-basic" />

            <Dropdown.Menu>
                <Dropdown.Item href="#/action-1">Criar prescrição</Dropdown.Item>
                <Dropdown.Item href="#/action-3">Detalha condições</Dropdown.Item>
                <Dropdown.Item href="#/action-3">Detalhe alergias</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>;
    }
}
export default Pacientes;
