import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Dropdown, NavDropdown } from 'react-bootstrap';
import { useRef, useState } from 'react';
import Assinatura from './Assinatura';

function MenuPrincipal({mostrarBotaoAssinatura = true, apenasLogo = false, logoLink = "/dashboard"}:
  {mostrarBotaoAssinatura?: boolean, apenasLogo?: boolean, logoLink?: string}) {

    const [showAssinatura, setShowAssinatura] = useState(false);
    const [tempoAssinatura, setTempoAssinatura] = useState<any>(null);

    function switchAssinatura(e: any): void {
      if(e.target.checked)
        setShowAssinatura(true);
    }

    const assinaturaRef = useRef(null);

    return (
      <>
        <Navbar>
            <Container>
                <Navbar.Brand href={logoLink}><img src="/logo192.png" alt="logo"/></Navbar.Brand>
                {apenasLogo ? null: <>
                <Nav className="d-none d-lg-flex me-auto">
                    <Nav.Link href="/pacientes">Pacientes</Nav.Link>
                    <Nav.Link href="/pacientes">Prescricao</Nav.Link>
                    <Nav.Link href="#features">Medicamentos</Nav.Link>

                    <NavDropdown title="Mais ..." id="nav-dropdown">
                        <NavDropdown.Item eventKey="modelo-prescricao" href="/modelo-prescricao">Modelos de Prescricão</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item eventKey="prescricoes" href="/prescricoes">Prescricoes</NavDropdown.Item>
                    </NavDropdown>
                </Nav>
                {mostrarBotaoAssinatura ?
                <Form className='mt-2'>
                    <Row>
                        <Col>
                            <Form.Check // prettier-ignore
                            	ref={(r:any) => assinaturaRef.current = r}
                                inline
                                type="switch"
                                label={tempoAssinatura ? "Assinatura válida por " + tempoAssinatura : "Assinatura digital"}
                                name='opcao'
                              onChange={(e) => switchAssinatura(e)}
                            />
                        </Col>
                    </Row>
                </Form>
                : null}
                <Dropdown className='d-none d-lg-flex'>
                    <Dropdown.Toggle variant="primary" id="dropdown-basic">
                        Criar Novo
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item href="/prescricao">Prescrição</Dropdown.Item>
                        <Dropdown.Item href="/paciente-editor">Paciente</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                <Dropdown className='d-none d-lg-flex'>
                    <Dropdown.Toggle id="dropdown-basic">
                        M
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item href="#/action-1">Sair</Dropdown.Item>
                    </Dropdown.Menu>

                </Dropdown>
            </>}
            </Container>
        </Navbar>
        <Assinatura 
		setValid={(v:boolean) => {
			var current:any = assinaturaRef.current;
			if(!current) return;
			current.checked = v;
		}}
        show={showAssinatura} handleClose={() => setShowAssinatura(false)} 
        setTempoAssinatura={setTempoAssinatura}/>
    </>
    );
}
export default MenuPrincipal;
