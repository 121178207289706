import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Stack from 'react-bootstrap/Stack';
import { Button, Card, Col, Modal, Nav, Row } from 'react-bootstrap';
import { ChangeEvent, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import MenuCelular from './MenuCelular';
import PrescricaoPaciente from './PrescricaoPaciente';
import medicamentos from './Dados';
import { ChevronLeft } from 'react-bootstrap-icons';
import MenuPrescricao from './MenuPrescricao';
import Pesquisa from '../componentes/Pesquisa';
import { useSendPost, useSendPut } from '../Server';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { useNavigate } from 'react-router-dom';
import '../index.css';
import 'overlayscrollbars/overlayscrollbars.css';
import {usePacientesDados} from './Pacientes';
import StringIndex from '../componentes/StringIndex';

const prescricoesRecentes = [
	{ id: 1, nome: 'JORGE DA SILVA', dataEmissao: '01/04/2024', itensPrescritos: 'Dipirona' },
	{ id: 3, nome: 'ANDREIA ALVES', dataEmissao: '01/04/2024', itensPrescritos: 'Azitromicina' },
	{ id: 2, nome: 'JOAO PIRES', dataEmissao: '30/03/2024', itensPrescritos: 'Loratadina, Diproiponato de clobetasol' },
	{ id: 4, nome: 'JAQUELINE FARIA', dataEmissao: '29/03/2024', itensPrescritos: 'Buscopan' },
];

enum State {
	INICIAL,
	paciente_selecionado,
	incluindo_prescricao,
	incluso_prescricao,
	incluir_alergia,
	incluir_condicao,
	gerar_prescricao
}

function Prescricao() {

	const navigate = useNavigate();

	const [medicamentosList, setMedicamentosList] = useState(StringIndex(medicamentos));

	const [pacientes, setPacientes] = useState([]);
	const [pesquisaMedicamento, setPesquisaMedicamento] = useState("");
	const [filteredMedicamento, setFilteredMedicamento] = useState<any[]>([]);

	const [stateData, setStateValue] = useState([State.INICIAL, null]);

	const [itensPrescricao, setItensPrescricao] = useState<any[]>([]);

	const medicamentoPesquisaInput = useRef<any>();

	const [dados, setDados] = useState<any>({data: 	new Date().toISOString().split('T')[0] });

	const state = stateData[0];
	const lastState = stateData[1];

	const adjustTextareaHeight = (element: HTMLTextAreaElement) => {
		element.style.height = 'auto';
		element.style.height = `${element.scrollHeight}px`;
	};

	useEffect(() => {
		const textareas = document.querySelectorAll('.textarea-custom');
		textareas.forEach((textarea) => adjustTextareaHeight(textarea as HTMLTextAreaElement));
	}, [itensPrescricao]);

	useEffect(() => {
		if (!pesquisaMedicamento || "" === pesquisaMedicamento || pesquisaMedicamento.length < 3) {
			setFilteredMedicamento([]);
			return;
		}

		let termos = pesquisaMedicamento.toLowerCase().split(" ");

		let f = medicamentosList.filter((m: any) =>
			termos.reduce((p, v) => p >= 0 ? 
          [1, 2, 3, 4].reduce((pv, cv) => pv < 0 ? m[cv].toLowerCase().indexOf(v) : pv, -1) : p, 1) > -1);


		const r = f.map((i: any) => {
			return {
				coluna1: (i[1] + " " + i[2] + " " + i[3]).toLowerCase(),
				coluna2: (i[5] + " " + i[6] + " " + i[7] + " " + i[8]).toLowerCase(),
				coluna3: (i[9] + " " + i[10]).toLowerCase(),
				id: i[0],
				nome: i[1],
				complemento: i[2],
				tipo_apresentacao: i[3],
				composicao: i[4],
				quantidade_apresentacao: i[5],
				unidade_apresentacao: i[6],
				quantidade_volume: i[7],
				unidade_volume: i[8],
				uso: i[9],
				laboratorio: i[10],
				preco_sem_impostos: i[11],
				conteudo: i[12],
				tipo_receituario: i[13]
			};
		});

		setFilteredMedicamento(r);
	}, [pesquisaMedicamento]);


  const pacientesDados = usePacientesDados(setPacientes);

  useEffect(() => {
    pacientesDados();
  },[]);
	

  const sendPost = useSendPost();
  const sendPut = useSendPut();

  var salvar = false;

	function salvarPrescricao() {
    if(salvar) return;

		const savePresc = () => {
			sendPost("prescricao/save",
			  {
				paciente: dados.paciente.paciente,
				data: dados.data,
				itens: itensPrescricao.map((k: any, i: number) => {
				  return {
					medicamento: k.id,
					posologia: k.posologia,
					quantidade: 1,
					sequencial: (i + 1),
				  }
				})
			  }, 
			  (response:any) => {
						setDados({ ...dados, id: response.data.prescricao });
						setState(State.gerar_prescricao);
			  });
		  }
	  
	  sendPut("paciente/update/condicao/" + dados.paciente.paciente,{
		gestante: dados.paciente.gravidez,
		lactante: dados.paciente.lactacao
	  }, 
	  (response:any) => {
			console.log(response);
			savePresc();
      salvar = false;
	  });
	}


	function setState(s: State) {
		setStateValue([s, ...stateData]);
	}

	function popSate() {
		setStateValue(stateData.slice(1));
	}

	useEffect(() => {
		if (isStateChange(State.INICIAL, State.incluindo_prescricao)) {
			medicamentoPesquisaInput.current?.focus();
		}
		else if (state === State.gerar_prescricao) {
			navigate("/prescricao-finalizada/" + dados.id);
		}
		//eslint-disable-next-line
	}, [stateData]);

	function isStateChange(from: State, to: State) {
		return lastState === from && state === to;
	}

	function iniciarIncluirPrescricao() {
		setState(State.incluindo_prescricao);
	}

	const [id, setId] = useState<number>(0);

	function nextId() {
		setId(id + 1);
		return id + 1;
	}

	function incluirPrescricao(_: any, p: any): void {
		setItensPrescricao([...itensPrescricao, { id: nextId(), posologia: "", ...p }]);
		setPesquisaMedicamento("");
		setState(State.incluso_prescricao);
	}

	function removerItemPrescricao(p: any): void {
		var nova = itensPrescricao.filter((i) => i.id !== p.id);
		setItensPrescricao(nova);
	}


	function itemPrescricaoOnChange(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, p: any, prop: string): void {
		var item = { ...p };
		item[prop] = e.target.value;

		var nova = itensPrescricao.map((i) => i.id === p.id ? item : i);
		setItensPrescricao(nova);
	}

	function incluirAlergiaCondica(): ReactNode {
		if (state !== State.incluir_alergia && state !== State.incluir_condicao) {
			return <></>
		}

		var def = (state === State.incluir_alergia ? "alergia" : "condicao");
		return <PrescricaoPaciente onHide={() => popSate()} defaultView={def} />
	}

	function mostarFormularioPrescricao(): boolean | undefined {
		return (state !== State.incluir_alergia && state !== State.incluir_condicao);
	}

	return (
		<>
			{incluirAlergiaCondica()}
				<MenuPrescricao gerarPrescricaoFn={salvarPrescricao} />
      {/* 
			<Modal
				show={mostarFormularioPrescricao()}
				size='xl'
				fullscreen={"xl-down"}
				aria-labelledby="example-custom-modal-styling-title"
			>
				<Modal.Body>
      */}
					<Container>
						<Row className="justify-content-end mb-2 ">
							<Col xs={{ span: 4 }} style={{ textAlign: 'right' }}>
								<Nav.Link href="/dashboard"><ChevronLeft /> Voltar</Nav.Link>
							</Col>
						</Row>
						<Row className="justify-content-end">
							<Col md={{ span: 4 }}>
								<Stack gap={2} direction="horizontal">
									<h6>Data</h6>
									<Form.Control type="date" value={dados.data} onChange={(p: any) => setDados({ ...dados, data: p.target.value })} />
								</Stack>
							</Col>
						</Row>
						<Row className="mt-3">
							<Col>
								<Pesquisa selecao={dados.paciente} label="Nome ou CPF" placeholder='Buscar pelo nome ou CPF' itens={pacientes}
									setSelecao={(p: any) => { 
										setDados({ ...dados, paciente: p })
										console.log(p);
									}} 
									filterFn={(p: any, pesq: any) => (p.nome.toLowerCase().indexOf(pesq) > -1 || p.cpf === pesq)} />
							</Col>
						</Row>

						<CondicoesAlergias setDados={setDados} dados={dados} setState={setState} />

						<Row key="itensPrescricao" className="border-top mt-3" lista-item-container>
							{itensPrescricao.map(
								(p) =>
									<Col key={p.id} sm={{ span: 12 }} className="border-bottom pb-3 mt-3">
										<Row>
											<Col key={p.id} sm={{ span: 12 }} className="border-bottom pb-3 mt-3">
												<Row>
													<Col xs={{ span: 7 }}>
														<div className="texto-nome-medicacao">
															<span className="bold">{p.nome + " " + p.complemento + ", "}</span> {p.tipo_apresentacao} {p.tipo_receituario}, {p.laboratorio}
															{/*<span className="bold">{p.nome + ", "}</span> {p.tipo_apresentacao}, {p.laboratorio} */}
														</div>
														<Form.Control
															as="textarea"
															rows={1}
															value={p.composicao}
															className="custom-select texto-composicao border-0 mt-1 textarea-custom"
															readOnly
															plaintext
															style={{ marginLeft: '7px' }}
														/>
														<Form.Control
															as="textarea"
															rows={1}
															value={"Embalagem com " + p.conteudo}
															className="custom-select texto-conteudo border-0 mt-1 textarea-custom"
															readOnly
															plaintext
															style={{ marginLeft: '7px' }}
														/>
														<Form.Control
															type="input"
															value={p.posologia}
															placeholder="Digite aqui a posologia"
															onChange={(e) => itemPrescricaoOnChange(e, p, 'posologia')}
															className="custom-select border-0 mt-1 textarea-custom placeholder-blue"
															list='pesquisa-medicamento'

														/>
													</Col>
													<Col xs={{ span: 3 }}>
														<Form.Control
															as="textarea"
															rows={1}
															value={p.quantidade_apresentacao + " " + p.unidade_apresentacao}
															className="custom-select border-0 textarea-custom"
															readOnly
															plaintext
															style={{ textAlign: 'right' }}
														/>
													</Col>
													<Col xs={{ span: 2 }}>
														<Button variant="outline-primary" className="mutted" onClick={() => removerItemPrescricao(p)}>
															Remover
														</Button>
													</Col>
												</Row>
											</Col>
										</Row>
									</Col>
							)}
						</Row>

						<Row style={{ display: mostrarCampoPrescricao() ? "block" : "none" }} className="mt-3">
							<Col>
								<Stack gap={2} direction="horizontal">
									<Form.Control ref={medicamentoPesquisaInput} type="input" value={pesquisaMedicamento} onChange={e => setPesquisaMedicamento(e.target.value)}
										placeholder='Digite aqui para buscar itens'
										className='custom-select placeholder-blue border-0' list='pesquisa-medicamento' />
								</Stack>

								<Col sm={{ span: 12 }} md={{ span: 4 }} className="position-absolute"
									style={{ marginLeft: 20, marginTop: -1, zIndex: 1000, minWidth: 700, display: (filteredMedicamento.length === 0 ? 'none' : 'block') }}>
									<ResultadoMedicamento filtered={filteredMedicamento} searchTerm={pesquisaMedicamento} selecaoMedicamento={incluirPrescricao} widthPesquisa={() => medicamentoPesquisaInput.current?.offsetWidth} />
								</Col>
							</Col>
						</Row>
						<Stack gap={2} className="mt-3 col-md-8 mx-auto">
							<Button variant="primary" onClick={() => iniciarIncluirPrescricao()}>Adicionar à prescrição</Button>
						</Stack>
						<Row className="mt-3">
							<Col>
								<h6 className="text-muted"><a href="outras">Ultimas prescrições</a></h6>
							</Col>
						</Row>
						<Row style={{ display: !mostrarPrecscricoesRecentes() ? "none" : '' }}>
							{prescricoesRecentes.filter((_, idx, arr) => idx < arr.length - arr.length % 2)
								.map(renderBlockPrescricao)}
						</Row>
					</Container>
{/*
				</Modal.Body>
			</Modal>*/}
			<MenuCelular />
		</>
	);

	function mostrarPrecscricoesRecentes(): boolean {
		return state === State.INICIAL && prescricoesRecentes.length > 0;
	}

	function renderBlockPrescricao(r: any): JSX.Element {
		return <Col className="mt-3 h-100" key={r.id} xs={{ span: 12 }} md={{ span: 6 }} lg={{ span: 3 }}>
			<Card key={r.id + "presc"} className="h-100">
				<Card.Body key={r.id + "body"}>
					<Card.Title key={r.id + "title"}>{r.nome}</Card.Title>
					<Card.Subtitle className="mb-2 text-muted" key={r.id + "st"}>Emitida em {r.dataEmissao}</Card.Subtitle>
					<Card.Text key={r.id + "text"}>{r.itensPrescritos}</Card.Text>
					<Card.Link href={"abrir-prescricao?id=" + r.id} key={r.id + "link"}> Exibir </Card.Link>
				</Card.Body>
			</Card>
		</Col>
	}

	function mostrarCampoPrescricao(): boolean {
		return state === State.incluindo_prescricao;
	}
}


function CondicoesAlergias({ setDados, dados, setState }:
	{ setDados: Function, dados: any, setState: (s: State) => void }) {

	const row = useRef();
	const [height, setHeight] = useState(130);

	useEffect(() => {
		const r: any = row.current;
		if (r) {
			setHeight(Math.min(parseFloat(r.offsetHeight), 130));
		}
	}, [row])

	return <Row className="mt-3" ref={row}>
		<Col sm={{ span: 6 }}>
			<Card className="mt-2" style={{ height: height }}>
				<Card.Body>
					<Card.Title>Condições</Card.Title>
					<Stack direction="horizontal" gap={3}>

						<Form.Check type="checkbox" label="Gestante" id="gravidez" 
							checked={dados.paciente?.gestante}
							onChange={(e) => setDados({ ...dados, paciente: {... dados.paciente, gravidez: e.target.checked }})} />

						<Form.Check type="checkbox" label="Lactaçao" id="lactacao"
							checked={dados.paciente?.lactante}
							onChange={(e) => setDados({ ...dados, paciente: {... dados.paciente, lactacao: e.target.checked }})} />
					</Stack>
					<Card.Subtitle className="mb-2 text-muted"> </Card.Subtitle>
					<Button type='button'
						onClick={() => setState(State.incluir_condicao)}
						variant='outline-primary'>Incluir condição</Button>
				</Card.Body>
			</Card>
		</Col>
		<Col sm={{ span: 6 }}>
			<Card className="mt-2" style={{ height: height }}>
				<Card.Body>
					<Card.Title>Alergias</Card.Title>
					<Card.Subtitle className="mb-2 text-muted">Nenhuma alergia</Card.Subtitle>
					<Button type='button'
						onClick={() => setState(State.incluir_alergia)}
						variant='outline-primary'>Incluir alergia</Button>
				</Card.Body>
			</Card>
		</Col>
	</Row>;
}

function ResultadoMedicamento({ filtered, searchTerm, selecaoMedicamento, widthPesquisa }:
	{ filtered: any[], searchTerm: string, selecaoMedicamento: Function, widthPesquisa: Function }) {

	const [coluna1, setColuna1] = useState({ coluna1: null, coluna2: null })
	const [coluna2, setColuna2] = useState({ coluna2: null })
	const [coluna3, setColuna3] = useState({ coluna3: null })

	const reset = useCallback(() => {
		scrollToZero(scroll1);
		scrollToZero(scroll2);
		scrollToZero(scroll3);

		setColuna1({ coluna1: null, coluna2: null })
		setColuna2({ coluna2: null })
		setColuna3({ coluna3: null })
	},[])

  useEffect(() => {
		if (filtered === null || filtered.length === 0)
			reset();
	}, [filtered, reset]);

	function updateColuna1(m: any, e: any) {
		if (!m) return;
		setColuna1(m);
		setColuna2({ coluna2: null });
		setColuna3({ coluna3: null });

		scrollToTop(scroll1, e);
		scrollToZero(scroll2);

	}

	function updateColuna2(m: any, e: any) {
		if (!m) return;
		setColuna2(m);
		setColuna3({ coluna3: null });

		scrollToTop(scroll2, e);
		scrollToZero(scroll3);
	}

	function updateColuna3(m: any, e: any) {
		if (!m) return;
		selecaoMedicamento(null, m);
		reset();
	}


	function scrollToZero(ref: any) {
		if (!ref.current || !ref.current.osInstance()) return;
		const viewport = ref.current.osInstance().elements().viewport;
		viewport.scrollTo({ top: 0 });
	}

	function scrollToTop(ref: any, e: any) {
		if (!ref.current) return;
		if (e.target.nodeName.toLowerCase() === 'p') {
			const viewport = ref.current.osInstance().elements().viewport;
			viewport.scrollTo({ top: e.target.parentElement.offsetTop, behavior: 'smooth' });
		}
		if (e.target.nodeName.toLowerCase() === 'li') {
			const viewport = ref.current.osInstance().elements().viewport;
			viewport.scrollTo({ top: e.target.offsetTop, behavior: 'smooth' });
		}
	}


	const termos = searchTerm.toLowerCase().split(" ");

	function sortColuna1(a: any, b: any) {
		let st = termos[0];
		let ac = a.coluna1;
		let bc = b.coluna1;

		let ai = ac.indexOf(st);
		let bi = bc.indexOf(st);

		return ai === 0 && bi === 0 ?
			(ac.length === bc.length ? ac.localeCompare(bc) : ac.length < bc.length ? -1 : 1) :
			ac.indexOf(st) === 0 ? -1 :
				bc.indexOf(st) === 0 ? 1 :
					ac.localeCompare(bc);
	}

	const fonteNormal = {
		fontSize: 15,
		margin: 0,
		padding: 0
	};

	const fonteNormalNegrito = {
		fontSize: 15,
		margin: 0,
		padding: 0,
		fontWeight: 'bold' 
	};
 
	const fonteMenor = {
		fontSize: 13,
		margin: 5,
		padding: 0,
		color: '#939394',
		marginTop: -17 // Ajuste para diminuir a distância entre as linhas
	};


	function conteudoColuna1(m: any): any {
		return (
			<div className="lista-item">
				<p>
					<span style={fonteNormalNegrito}>{m.nome} {m.complemento}</span>
					<span style={fonteNormal}>{", " + m.tipo_apresentacao }</span>
				</p>
				<p style={fonteMenor}>{m.composicao}</p>
			</div>
		);
	}

	function conteudoColuna2(m: any): any {
		/*
		return (m.quantidade_volume ?
					<div className="lista-item">
							<p style={fonteNormal}>{m.quantidade_apresentacao + " " +
								m.unidade_apresentacao + " de " + m.quantidade_volume + " " +
								(m.unidade_volume == "un" ? " " : m.unidade_volume) +
								" (Uso " + m.uso.toLowerCase() + ")"}</p>
						</div>
						:
						<div className="lista-item">
							<p style={fonteNormal}>{m.quantidade_apresentacao + " " +
								m.unidade_apresentacao +
								(m.unidade_volume == "un" ? " " : m.unidade_volume) +
								" (Uso " + m.uso.toLowerCase() + ")"}</p>
						</div>
						)
		*/
		return(
		<div className="lista-item">
				<p style={fonteNormal}>{m.conteudo}</p>
		</div>
		)
	}


	function conteudoColuna3(m: any): any {
		return (
			<div className="lista-item">
				<p style={fonteNormal}><span>{m.laboratorio}</span>
					<span style={{ float: 'right' }} className="medicamentoPreco">{"R$ " + m.preco_sem_impostos}</span>
				</p>
			</div>
		)
	}


	const scroll1 = useRef<any>();
	const scroll2 = useRef<any>();
	const scroll3 = useRef<any>();

	return <>
		<Card style={{ width: widthPesquisa(), border: "1px solid #E8F2FE" }}>
			<Card.Body>
				<Row>
					<Col md={{ span: 7 }} style={{ borderRight: "1px solid #E8F2FE", borderBottom: "1px solid #E8F2FE" }}>
						<OverlayScrollbarsComponent ref={scroll1} defer style={{ height: 400 }}>
							<ul className="medicamento-grupos">
								{filtered == null ? "" :
									filtered
										.sort((a, b) => sortColuna1(a, b))
										.reduce((p, c) => p.length === 0 ? [c] : p[p.length - 1].coluna1 === c.coluna1 ? p : [...p, c], [])
										.map((m: any) => coluna1.coluna1 === m.coluna1 ?
											<li
												style={{
													color: '#000', // Texto Azul
													backgroundColor: '#E8F2FE', // Fundo Azul Claro
												}}
											>
												{conteudoColuna1(m)}
											</li> :
											<li style={{ color: coluna1.coluna1 != null ? '#888' : '#000' }} onClick={(e) => updateColuna1(m, e)}>
												{conteudoColuna1(m)}
											</li>
										)}
							</ul>
						</OverlayScrollbarsComponent>
					</Col>

					<Col md={{ span: 3 }} style={{ borderRight: "1px solid #E8F2FE", borderBottom: "1px solid #E8F2FE" }}>
						<OverlayScrollbarsComponent ref={scroll2} defer className="custom-scrollbar" style={{ height: 400 }}>
							<ul className="medicamento-grupos">
								{filtered == null ?
									<div className="text-secondary" style={{ marginTop: "40%", textAlign: "center" }}>Selecione um filtro</div>
									: filtered
										.sort((a, b) => a.coluna2.localeCompare(b.coluna2))
										.filter((m: any) => coluna1.coluna1 === m.coluna1)
										.reduce((p: any, c: any) => p.length === 0 ? [c] : p[p.length - 1].coluna2 === c.coluna2 ? p : [...p, c], [])
										.map((m: any) => m.coluna2 === coluna2.coluna2 ?
											<li style={{ color: '#000', backgroundColor: '#E8F2FE' }}>{conteudoColuna2(m)}</li> :
											<li style={{ color: coluna2.coluna2 != null ? '#888' : '#000' }} onClick={(e) => updateColuna2(m, e)}>{conteudoColuna2(m)}</li>)
								}
							</ul>
						</OverlayScrollbarsComponent>
					</Col>
					<Col md={{ span: 2 }} style={{ borderRight: "none", borderBottom: "1px solid #E8F2FE" }}>
						<OverlayScrollbarsComponent ref={scroll3} defer className="custom-scrollbar" style={{ height: 400 }}>
							<ul className="medicamento-grupos">
								{filtered == null ?
									<div className="text-secondary" style={{ marginTop: "40%", textAlign: "center" }}>Selecione um filtro</div>
									: filtered
										.filter((m) =>
											coluna1.coluna1 === m.coluna1 &&
											coluna2.coluna2 === m.coluna2)
										.reduce((p, c) => p.length === 0 ? [c] : p[p.length - 1].coluna3 === c.coluna3 ? p : [...p, c], [])
										.map((m: any) => m.coluna3 === coluna3.coluna3 ?
											<li style={{ color: '#000', backgroundColor: '#E8F2FE' }}>{conteudoColuna3(m)}</li> :
											<li style={{ color: coluna3.coluna3 != null ? '#888' : '#000' }} onClick={(e) => updateColuna3(m, e)}>{conteudoColuna3(m)}</li>)
								}
							</ul>
						</OverlayScrollbarsComponent>
					</Col>
				</Row>
			</Card.Body>
		</Card>

	</>

}
export default Prescricao;
