import { ChangeEvent } from "react";
import { Button, Col, ListGroup, Modal, Row, Tab, Tabs } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import ButtonLink from "../ButtonLink";

function PrescricaoPaciente(
  {
    onHide,
    defaultView
  }:
    {
      onHide: any,
      defaultView: any
    }) {

  return <Modal
    show={true}
    size='lg'
    backdrop="static"
    fullscreen={"sm-down"}
    onHide={onHide} >
    <Modal.Header closeButton>
      <Modal.Title>
        Paciente
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Form>
        <Tabs
          defaultActiveKey={defaultView}
          id="uncontrolled-tab-example"
          className="mb-3"
        >
          <Tab eventKey="info" title="Informações">

            <Form.Group>
              <Form.Label>Nome</Form.Label>
              <Form.Control type="input" placeholder="" />
            </Form.Group>
            
            <Form.Group>
              <Form.Label>Documento</Form.Label>
              <Form.Control type="input" placeholder="" />
            </Form.Group>

          </Tab>
          <Tab eventKey="alergia" title="Alergias">
            <Row>
              <Col xs={{ span: 10 }}>
                <Form.Control type="input" placeholder="Pesquise aqui a alergia" />
              </Col>
              <Col xs={{ span: 2 }}>
                <Button>Incluir</Button>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <ListGroup className="justify-content-between">
                  {/*
                  <ListGroup.Item as="li" className="d-flex justify-content-between " >
                    <div className="mt-1"> Quitosana </div>
                    <Button variant="link" className="secondary">Remover</Button>
                  </ListGroup.Item>
                  <ListGroup.Item as="li" className="d-flex justify-content-between " >
                    <div className="mt-1"> Leite </div>
                    <Button variant="link" className="secondary">Remover</Button>
                  </ListGroup.Item>
*/}
                </ListGroup>
              </Col>
            </Row>
          </Tab>
          <Tab eventKey="condicao" title="Condições" >
            <Row className="mt-3">
              <Col>
                <Form.Check type="checkbox" label="Gravidez" id="gravidez" />
              </Col>
            </Row>
          </Tab>
        </Tabs>
      </Form>
    </Modal.Body>
  </Modal>;
}
export default PrescricaoPaciente;
