class Config {
  private static instance:Config;

  private constructor(){ }

  public static get(){
    if(!Config.instance){
      Config.instance = new Config();
    }
    return Config.instance;
  }

  public url(path: string){
    return process.env.REACT_APP_SERVER_BASE_URL + path;
  }

  public shouldUpdate(id:string, time:number){
    const elapsed = (Date.now() - time) / 1000;
    switch (id) {

      case "pacientes":
//        return elapsed > 3600;
        return elapsed > 0;

      default:
        break;
    }
  }
}

export default Config;
