import Form from 'react-bootstrap/Form';
import { Button, Offcanvas } from 'react-bootstrap';

function PacientePrescricao() {
    return <>
        <Offcanvas show={true} placement='end'>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>Dados do paciente</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <Form>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Nome</Form.Label>
                        <Form.Control type="email" placeholder="Digite o nome do seu paciente" />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>CPF</Form.Label>
                        <Form.Control type="email" placeholder="Digite apenas os números do CPF" />
                    </Form.Group>
                    <Form.Group className="mb-3" >
                        <Button type='button' variant='primary'>Atualizar</Button>
                    </Form.Group>
                </Form>
            </Offcanvas.Body>
        </Offcanvas>
    </>
}
export default PacientePrescricao;
