import axios from "axios";
import { useKeycloak } from "keycloak-react-web";
import { useNavigate } from "react-router-dom";
import Config from "./prescrito/Config";


export function useSendPut() {

    const keycloak = useKeycloak();
    
    const navigate = useNavigate();

    return (path:string, data:any, onResponse:Function) => {
      updateKeycloakToken(keycloak, navigate);
      axios.defaults.headers.common = { Authorization: 'Bearer ' + keycloak.keycloak.token };
      const url = Config.get().url(path);
      axios
        .put(url, data)
        .then((response) => {
          onResponse.call(globalThis, response);
        });
    }
}

function updateKeycloakToken(keycloak:any, navigate:Function) {
    if (keycloak.keycloak.isTokenExpired()) {
        keycloak.keycloak.updateToken(0).catch((error:any) => {
            console.log(error);
            navigate("/login");
        });
    }
}

export function useSendGetFile() {

    const keycloak = useKeycloak();
    const navigate = useNavigate();

    return (path:string, onResponse:Function, onError:Function = (e:any)=>null) => {
      updateKeycloakToken(keycloak, navigate);
      axios.defaults.headers.common = { Authorization: 'Bearer ' + keycloak.keycloak.token };
      const url = Config.get().url(path);

      axios
        .get(url, {
			responseType: "blob"
		})
        .then((response:any) => {
          onResponse.call(globalThis , response);
        }).catch((error:any) => {
          onError.call(globalThis, error);
        });
    }
}

export function useSendPostFile() {

    const keycloak = useKeycloak();
    const navigate = useNavigate();

    return (path:string, data:any, onResponse:Function) => {
      //updateKeycloakToken(keycloak, navigate);
      //axios.defaults.headers.common = { Authorization: 'Bearer ' + keycloak.keycloak.token };
      const url = Config.get().url(path);

      axios
        .post(url, data, {
			responseType: "blob"
		})
        .then((response) => {
          onResponse.call(globalThis, response);
        });
    }
}

export function useSendGet() {

    const keycloak = useKeycloak();
    
    const navigate = useNavigate();

    return (path:string, onResponse:Function) => {
      updateKeycloakToken(keycloak, navigate);
      axios.defaults.headers.common = { Authorization: 'Bearer ' + keycloak.keycloak.token };
      const url = Config.get().url(path);

      axios
        .get(url)
        .then((response:any) => {
          onResponse.call(globalThis , response);
        });
    }
}

export function useSendPost() {

    const keycloak = useKeycloak();
    const navigate = useNavigate();

    return (path:string, data:any, onResponse:Function) => {
      updateKeycloakToken(keycloak, navigate);
      axios.defaults.headers.common = { Authorization: 'Bearer ' + keycloak.keycloak.token };
      const url = Config.get().url(path);

      axios
        .post(url, data)
        .then((response) => {
          onResponse.call(globalThis, response);
        });
    }
}
