import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Button, ButtonGroup, Dropdown, NavDropdown } from 'react-bootstrap';
import { ChangeEvent, useRef, useState } from 'react';
import { useSendPost } from '../Server';
import Config from './Config';
import Assinatura from './Assinatura';

function MenuPrescricao({gerarPrescricaoFn}:{gerarPrescricaoFn: Function}) {

    const sendPost = useSendPost();

    const [showAssinatura, setShowAssinatura] = useState(false);
    const [tempoAssinatura, setTempoAssinatura] = useState<any>(null);   

    function switchAssinatura(e: ChangeEvent<HTMLInputElement>): void {
      if(e.target.checked)
        setShowAssinatura(true);
    }

    const assinaturaRef = useRef(null);

    return (
      <>
        <Navbar>
            <Container>
                <Navbar.Brand href="/dashboard"><img src="logo192.png" alt="logo"/></Navbar.Brand>
                <Nav className="d-none d-lg-flex me-auto">
                    <Nav.Link href="/pacientes">Pacientes</Nav.Link>
                    <Nav.Link href="/prescricao-configuracao">Modelos de Prescricão</Nav.Link>

                    <NavDropdown title="Mais ..." id="nav-dropdown">
                        <NavDropdown.Item eventKey="4.1">Prescricoes Anteriores</NavDropdown.Item>
                    </NavDropdown>
                </Nav>
                <Form className='mt-2'>
                    <Row>
                        <Col >
                            <Form.Check // prettier-ignore
                            	ref={(r:any) => assinaturaRef.current = r}
                                inline
                                type="switch"
                                label={tempoAssinatura ? "Assinatura válida por " + tempoAssinatura : "Assinatura digital"}
                                name='opcao'
                              onChange={(e) => switchAssinatura(e)}
                            />
                            
                        </Col>
                    </Row>
                </Form>
                <Dropdown className='d-lg-flex' as={ButtonGroup}>
                    <Button onClick={() => gerarPrescricaoFn()}>Gerar Prescrição</Button>
                    <Dropdown.Toggle split variant="primary" id="dropdown-basic" />
                    <Dropdown.Menu>
                        <Dropdown.Item href="#/copiar-para-prontuario">Copiar para prontuário</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </Container>
        </Navbar>
        <Assinatura setValid={(v:boolean) => {
			var current:any = assinaturaRef.current;
			if(!current) return;
      current.checked = v;
		}}
		 show={showAssinatura} handleClose={() => setShowAssinatura(false)} 
        setTempoAssinatura={setTempoAssinatura} />
    </>
    );
}
export default MenuPrescricao;
