import { useEffect, useRef, useState } from 'react';
import { PrescricaoConfiguracaoDados } from './PrescricaoConfiguracaoDados';

function PrescricaoConfiguracaoEspecialExemplo({display, config, setConfig }: {display:boolean, config: PrescricaoConfiguracaoDados, setConfig: Function }) {


  const page = useRef<any>(null);

 const borderStyle = "1px dashed pink";
  const labelBorderStyle = "1px solid pink";
  const labelBackgroundColor = "white";
  const labelTextColor = "darkred";
  const [labelCenter, setLabelCenter] = useState({ width: 10, height: 7});

  function contentHeight(): number {
    return px(config.pageHeight - config.footerHeight - config.headerHeight);
  }

  function ptfn(size: number): number {
    return size * pageSize.width / 665.0;
  }

  function ptf(size: number): string {
    return ptfn(size) + "pt";
  }

  function px(value: number): number {
    return value * pageSize.width / 14.8;
  }

  const [pageSize, setPageSize] = useState<any>({ width: 0, height: 0 });

  
  function updatePageWidth() {
    setPageSize({ width: Math.max(page.current?.offsetWidth, 300), height: Math.max(page.current?.offsetHeight, 300) });
    setLabelCenter({width: ptfn(18), height: ptfn(10)})
    return 0;
  }

  function globalPosition(item: any): any {
    let o = item;
    if (!o) return null;
    var l = o.offsetLeft;
    var t = o.offsetTop;

    while (o = o.offsetParent)
      l += o.offsetLeft;

    o = item;
    while (o = o.offsetParent)
      t += o.offsetTop;
    return [l, t];
  }


  const [dragAreas, setDragAreas] = useState<any>(null);
  useEffect(() => {
    const d = 10;

    const da = [
      {
        x: px(config.marginLeft) - d,
        y: px(config.headerHeight),
        width: 2 * d,
        height: contentHeight(),
        cursor: 'col-resize',
        update: (x: number, _: number) => {
          setConfig({ ...config, marginLeft: x / px(1) });
        }
      },

      {
        x: pageSize.width - px(config.marginRight) - d,
        y: px(config.headerHeight),
        width: 2 * d,
        height: contentHeight(),
        cursor: 'col-resize',
        update: (x: number, _: number) => {
          setConfig({ ...config, marginRight: (pageSize.width - x) / px(1) });
        }
      },
      {
        x: 0,
        y: px(config.headerHeight) - d,
        width: pageSize.width,
        height: 2 * d,
        cursor: 'row-resize',
        update: (_: number, y: number) => {
          setConfig({ ...config, headerHeight: (y) / px(1) });
        }
      },
      {
        x: 0,
        y: pageSize.height - px(config.footerHeight) - d,
        width: pageSize.width,
        height: 2 * d,
        cursor: 'row-resize',
        update: (_: number, y: number) => {
          setConfig({ ...config, footerHeight: (pageSize.height - y) / px(1) });
        }
      }

    ];
    setDragAreas(da);
  }, [pageSize, config])

  useEffect(() => {
    updatePageWidth();
  }, [display])

  useEffect(() => {
    updatePageWidth();
    window.addEventListener("resize", () => {
      updatePageWidth();
    });
    setTimeout(updatePageWidth, 1000);
  }, [])


  const [dragElement, setDragElement] = useState<any>(null);
  const globalPagePos = globalPosition(page.current);

  function dragEnter(ev: any): void {
    let elm;
    if (elm = isDragArea(ev)) {
      setDragElement(elm);
    }
  }

  function isDragArea(ev: any) {
    const x = ev.pageX - globalPagePos[0];
    const y = ev.pageY - globalPagePos[1];
    for (let i = 0; i < dragAreas.length; i++) {
      const elm = dragAreas[i];
      if (x > elm.x && x < elm.x + elm.width && y > elm.y && y < elm.y + elm.height) {
        return elm;
      }
    }
    return null;
  }

  function dragExit(ev: any): void {
    setDragElement(null);
  }

  function mouseMove(ev: any): void {
    console.log("Move");
    let elm;
    if (elm = isDragArea(ev)) {
      page.current.style.cursor = elm.cursor;
    }
    else {
      page.current.style.cursor = 'auto';
    }

    if (dragElement == null) return;
    dragElement.update(ev.pageX - globalPagePos[0], ev.pageY - globalPagePos[1]);
  }

  function formatUnitLabel(value: number): string {
    return value ? value.toFixed(1): "0.0";
  }

  function headerContent() {
    if (!config.habilitarCabecalhoRodape) {
      return;
    }


    const textMarginTop = config.headerHeight / 2 - (config.nomeTamanho + config.descricaoTamanho) / 50;
    const imgMarginTop = config.headerHeight / 2 - config.headerImgSize / 2;

    const centerMarginTop = config.headerHeight / 2 - 
      config.headerImgSize / 2 - (config.nomeTamanho + config.descricaoTamanho) / 40;

    const elemNome = <p style={{
      margin: 0, padding: 0,
      fontSize: ptf(config.nomeTamanho),
      color: config.nomeCor,
      fontFamily: config.nomeFonte,
    }}>{config.nome}</p>;

    const elemDescricao = <p style={{
      margin: 0, padding: 0,
      fontSize: ptf(config.descricaoTamanho),
      color: config.descricaoCor,
      fontFamily: config.descricaoFonte,
    }}>{config.descricao}</p>;

    if (config.cabecalhoAlinhamento == 'left') {
      return <>
        <img style={{
          display: 'block', float: 'left',
          marginTop: px(imgMarginTop),
          marginLeft: px(config.marginLeft)
        }}
          src={config.headerImgUrl}
          height={px(config.headerImgSize)} />

        <div style={{ float: 'left', marginLeft: px(.3), marginTop: px(textMarginTop) }}>
          {elemNome}
          {elemDescricao}
        </div>
      </>
    }

    else if (config.cabecalhoAlinhamento == 'center') {
      return <div style={{paddingTop: px(centerMarginTop)}}>
        <img style={{ 
          display: 'block',
          marginLeft: 'auto',
          marginRight: 'auto'
        }}
          src={config.headerImgUrl}
          height={px(config.headerImgSize)} />

        <div style={{ display: 'block', textAlign: 'center'}}>
          {elemNome}
          {elemDescricao}
        </div>
      </div>
    }

    else if (config.cabecalhoAlinhamento == 'right') {
      return <>
        <img style={{ 
          float: 'right', 
          marginTop: px(imgMarginTop),
          marginRight: px(config.marginRight) 
        }}
          src={config.headerImgUrl}
          height={px(config.headerImgSize)} />

        <div style={{ float: 'right', textAlign: 'right', marginRight: px(.5), marginTop: px(textMarginTop) }}>
          {elemNome}
          {elemDescricao}
        </div>
      </>
    }
  }

  function rodapeContent() {
    if (!config.habilitarCabecalhoRodape) {
      return;
    }
    return <>
      <pre style={{margin:0, marginRight: px(config.marginRight), 
        marginLeft: px(config.marginLeft) , padding: 0, 
        fontSize: ptf(config.rodapeTamanho), fontFamily: config.rodapeFonte, 
        color: config.rodapeCor, textAlign: config.rodapeAlinhamento }}>
          {config.rodape}
      </pre>
    </>
  }

  return <div id="page1" style={{ fontSize: ptf(config.textoTamanho), display:display? 'block': 'none' }} ref={page} onMouseDown={(ev) => dragEnter(ev)} onMouseUp={(ev) => dragExit(ev)} onMouseMove={(ev) => mouseMove(ev)}>
    <div id="header" style={{ height: px(config.headerHeight), borderBottom: borderStyle, padding: 0 }}>
      <span style={{
        position: "absolute", border: labelBorderStyle, backgroundColor: labelBackgroundColor,
        color: labelTextColor,
        top: px(config.headerHeight) - labelCenter.height,
        left: pageSize.width / 2 - labelCenter.width
      }}>{formatUnitLabel(config.headerHeight)} cm</span>
      {headerContent()}
    </div>
    <div id="content" style={{
      padding: 0,
      height: contentHeight(),
      marginLeft: px(config.marginLeft),
      marginRight: px(config.marginRight),
      borderLeft: borderStyle, borderRight: borderStyle
    }}>
      <span style={{
        position: "absolute", border: labelBorderStyle, backgroundColor: labelBackgroundColor,
        color: labelTextColor,
        top: px(config.headerHeight) + contentHeight() / 2,
        left: px(config.marginLeft) - labelCenter.width,
      }}>{formatUnitLabel(config.marginLeft)} cm</span>

      <ExemploConteudo config={config} pageSize={pageSize} px={px} ptf={ptf} />

      <span style={{
        position: "absolute", border: labelBorderStyle, backgroundColor: labelBackgroundColor,
        color: labelTextColor,
        top: px(config.headerHeight) + contentHeight() / 2,
        right: px(config.marginRight) - labelCenter.width
      }}>{formatUnitLabel(config.marginRight)} cm</span>

    </div>
    <div id="footer" style={{ height: px(config.footerHeight), borderTop: borderStyle, zIndex: 100 }}>
      <span style={{
        position: "absolute", border: labelBorderStyle, backgroundColor: labelBackgroundColor,
        color: labelTextColor,
        bottom: px(config.footerHeight) - labelCenter.height,
        left: pageSize.width / 2 - labelCenter.width
      }}>{formatUnitLabel(config.footerHeight)} cm</span>

      {rodapeContent()}

    </div>
  </div>
}

function ExemploConteudo({ config, pageSize, px, ptf }: 
    { config: PrescricaoConfiguracaoDados, pageSize: { width: number, height: number }, px: Function, ptf: Function }) {

  const identificacaoRef = useRef(null);
  const identificacaoPacRef = useRef(null);

  const data = [
    {
      indice: 1,
      nome: "ABC 10mg/mL",
      envelope: "Solução (30ml)",
      fabricante: "Kley Hertz",
      embalagens: "3 embalagens",
      posologia: "Tomar 3 vezes ao dia"
    },
    {
      indice: 2,
      nome: "Asea HCT",
      envelope: "Comprimido revestido (30un)",
      fabricante: "Supera Farma",
      embalagens: "1 embalagens",
      posologia: "Tomar 3 vezes ao dia"
    },
    {
      indice: 3,
      nome: "Berotec 0,25mg/mL",
      envelope: "Comprimido revestido (30un)",
      fabricante: "Supera Farma",
      embalagens: "1 embalagens",
      posologia: "Tomar 3 vezes ao dia"
    },
  ]

  const espacamento = config.espacamento == "pequeno" ? .6:
      config.espacamento == "medio"? .9: 1.2;
 
  const pageWidth = config.pageWidth - config.marginLeft - config.marginRight - .1;
  const halfpage =pageWidth / 2;

  const identificacaoTableRow = (label:string, value: string) => {
    return <div style={{ clear: 'left'}} >
      <span style={{ float: 'left', paddingLeft: px(.1),  
        fontSize: ptf(config.textoTamanho), fontFamily: config.textoFonte  }}><b>{label}</b> </span>
      <div style={{ float: 'left', paddingLeft: px(.1),
      fontSize: ptf(config.textoTamanho), fontFamily: config.textoFonte}}>{value}</div>
    </div>
  }
  const identificacaoTableRow2 = (label:string) => {
    return <div style={{ clear: 'left'}} >
      <span style={{ width: '100%', float: 'left', textAlign: 'center',
        fontSize: ptf(config.textoTamanho), fontFamily: config.textoFonte,
        paddingLeft: px(.1) }}>{label} </span>
    </div>
  }
  const identificacaoTableRow3 = (label:string, bottom: number) => {
    return <div style={{ clear: 'left'}} >
      <span style={{ float: 'left', marginTop: px(bottom), 
        fontSize: ptf(config.textoTamanho), fontFamily: config.textoFonte,
        textAlign: 'center', paddingLeft: px(.1) }}><b>{label}</b> </span>
    </div>
  }
  const identificacaoTableRow4 = (label:string|null) => {
    return <div style={{ clear: 'left'}} >
      {label != null ?
      <pre style={{ fontSize: ptf(config.textoTamanho), fontFamily: config.textoFonte, 
           display:'block', overflow: 'clip', marginBottom: px(.1), marginRight: px(.1), 
          marginLeft: px(.1)}}><b>{label}</b> ___________________________________</pre>

      :<pre style={{ fontSize: ptf(config.textoTamanho), fontFamily: config.textoFonte, 
           display:'block', overflow: 'clip', marginBottom: px(.1), marginRight: px(.1), 
          marginLeft: px(.1), 
          }}> ___________________________________</pre>}
    </div>
  }

  var identificacaoHeight = 30.0;
  if(identificacaoRef.current){
    var cur:any = identificacaoRef.current;
    identificacaoHeight = cur.offsetHeight;
  }

  var identificacaoPacHeight = 30.0;
  if(identificacaoPacRef.current){
    var cur:any = identificacaoPacRef.current;
    identificacaoPacHeight = cur.offsetHeight;
  }


  return <>
    <div ref={identificacaoRef} style={{ width: px(halfpage), minHeight: px(3.3) , float: 'left', border: '1px solid black', fontSize: ptf(config.textoTamanho), fontFamily: config.textoFonte }}>
      <div style={{ textAlign: 'center', borderBottom: '1px solid black' }}>
        <span>IDENTIFICACAO DO EMITENTE</span>
      </div>
      {identificacaoTableRow('Nome:', config.nomeCompleto)}
      {identificacaoTableRow('CRM:', config.crm)}
      {identificacaoTableRow('Endereco:', config.endereco)}
      {identificacaoTableRow('Cidade/UF:', config.cidadeUf)}
    </div>
    <div style={{ width: px(halfpage), height: identificacaoHeight , float: 'right', border: '1px solid black', fontSize: ptf(config.textoTamanho), fontFamily: config.textoFonte }}>
      <div style={{ textAlign: 'center', borderBottom: '1px solid black' }}>
          <span>RECEITUARIO DE CONTROLE ESPECIAL</span>
      </div>
      {identificacaoTableRow('Data:', '23/04/2024')}
      {identificacaoTableRow2('1a via Farmácia')}
      {identificacaoTableRow2('2a via Paciente')}
      {identificacaoTableRow3('Assinatura:', .2)}
    </div>
    
    <div style={{ clear: 'both', fontSize: ptf(config.textoTamanho), fontFamily: config.textoFonte }}>
    <ul style={{ padding: 0, display: 'block', margin: 0, paddingTop: px(.3) }} >
      {data.map((i: any) => <li style={{ position: 'relative', marginBottom: px(espacamento), listStyle: 'none' }}>
        {config.mostartUnidades ? 
        <div style={{ borderTop: ".1px "+config.separadorUnidades+" #666", position: 'absolute', bottom: px(.2), right: px(config.marginRight), left: px(config.marginLeft) }}></div>
        :""}

        <div style={{ float: 'left', marginRight: px(.1) }}>{i.indice}.</div> <span style={{ backgroundColor: '#fff', zIndex: 99, position: 'relative' }}>
          <b>{i.nome}</b>, {i.envelope} {config.mostrarNomeFabricante? i.fabricante: ''}
        </span>

        {config.mostartUnidades ? 
        <span style={{ backgroundColor: '#fff', zIndex: 99, position: 'absolute', right: 1 }}>
          {i.embalagens}
        </span>
        : ""}

        <div style={{ position: 'absolute', left: px(.4) }}>
          <div>
            {i.composicao?.map((c: any) => <p style={{ marginBottom: px(.1), color: '#666' }}>{c}</p>)}
          </div>
          {i.posologia}
        </div>

      </li>)}
    </ul>

    <div style={{ position: 'absolute', bottom: px(config.footerHeight + .1), right: px(config.marginRight), left: px(config.marginLeft) }}>
    <div ref={identificacaoPacRef} style={{ width: px(halfpage) , float: 'left', border: '1px solid black', fontSize: ptf(config.textoTamanho), fontFamily: config.textoFonte }}>
      <div style={{ textAlign: 'center', borderBottom: '1px solid black' }}>
        <span>IDENTIFICAÇÃO DO PACIENTE</span>
      </div>
      {identificacaoTableRow3('', .2)}
      {identificacaoTableRow4('Nome:')}
      {identificacaoTableRow4(null)}
      {identificacaoTableRow4('Telefone:')}
      {identificacaoTableRow4('Endereco:')}
      {identificacaoTableRow4(null)}
      {identificacaoTableRow4('Cidade/UF:')}
    </div>
    <div style={{ width: px(halfpage), height: identificacaoPacHeight , float: 'right', border: '1px solid black', fontSize: ptf(config.textoTamanho), fontFamily: config.textoFonte }}>
      <div style={{ textAlign: 'center', borderBottom: '1px solid black' }}>
          <span>IDENTIFICACAO FORNECEDOR</span>
      </div>
      {identificacaoTableRow3('', .3)}
      {identificacaoTableRow4('Data:')}
      {identificacaoTableRow3('Assinatura Farmacêutico:', .2)}
    </div>
    </div>
  </div>
  </>


}

export default PrescricaoConfiguracaoEspecialExemplo;
