import Form from 'react-bootstrap/Form';
import { Button, ButtonGroup, Card, Col, Container, Dropdown, ListGroup, Nav, Row, Stack, Table } from 'react-bootstrap';
import MenuCelular from './MenuCelular';
import MenuPrincipal from './MenuPrincipal';
import { useEffect, useState } from 'react';
import Pesquisa from '../componentes/Pesquisa';
import { useKeycloak } from 'keycloak-react-web';
import { Link, redirect, useNavigate, useParams } from 'react-router-dom';
import { useSendGet, useSendGetFile, useSendPost, useSendPut } from '../Server';
import Config from './Config';
import { url } from 'inspector';

function PrescricaoFinalizada() {
  const { id } = useParams();
  const keycloak = useKeycloak();

  const navigate = useNavigate();
  const userData = keycloak.keycloak.idTokenParsed;

  const [dados, setDados] = useState<any>({ prescricao: id });
  const [status, setStatus] = useState<any>(null);

  const [selected, setSelected] = useState("dados");

  const sendPostF = useSendPost();
  const sendPut = useSendPut();

  const sendGet = useSendGet();
  const sendGetFile = useSendGetFile();

  function sendDados() {
    const d = { prescricao: id, telefone: dados.telefone };
    sendPostF("prescricao/enviar_mensagem", d,
      (response: any) => {
        console.log(response);
        alert("Mensagem enviada");
      });
  }

  var gerar = false;
  useEffect(() => {
    if(gerar) return;

    gerar = true;
    if (id) {
      setTimeout(() => {
        console.log("gerando pdf " + id);
        sendGet("prescricao/gerar_pdf/" + id,
          (response: any) => {
            console.log('prescricao', response.data);
            const d = response.data;
            setDados({
              ...dados, ...d,
              telefone: checkWhatsappPhone(d.pacienteObj?.telefone)
            });
            gerar = false;
          });
      }, 0);
    }
  }, [id]);

  function checkWhatsappPhone(telefone: any) {
    if(telefone == null)
      return;
    if (telefone.length < 12) {
      return '+55' + telefone
    } else {
      return telefone
    }
  }

  function downloadPrescricao() {
    sendGetFile("prescricao/download_prescricao/" + id, (response: any) => {
      const href = URL.createObjectURL(response.data);

      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', 'prescricao' + id + '.pdf');
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });
  }
  return <>
    <MenuPrincipal />
    <Container>
      <Row className="mt-5">
        <Col>
          <h2>Arquivo prescricão </h2>
        </Col>
        <Row className='mt-3'>
          <Col sm={{ span: 12 }}>
            {dados.arquivo ?
              <Button variant="outline-primary" onClick={() => downloadPrescricao()}>Clique aqui para fazer o download do arquivo</Button>
              : <p>Gerando arquivo da prescricao ...</p>}
          </Col>
        </Row>
      </Row>
      <Row className="mt-5">
        <Col>
          <h2>Enviar prescricão </h2>
        </Col>
      </Row>
      <Form>
        <Row>
              <Form.Label>Informe o telefone para enviar pelo WhatsApp</Form.Label>
          <Col sm={{ span: 6 }}>
              <Form.Control id="telefone" type="input" placeholder="Digite o telefone para enviar a prescrição"
                value={dados.telefone} onChange={e => setDados({ ...dados, telefone: e.target.value })} />
          </Col>
          <Col sm={{ span: 6 }}>
            <Button type='button' variant='primary' onClick={() => sendDados()}>Enviar</Button>
          </Col>
        </Row>
      </Form>
    </Container>
    <MenuCelular />
  </>
}
export default PrescricaoFinalizada;
